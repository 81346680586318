@media screen and (max-width : 820px) {
    .home3 {
        height: 130vh;

        >div {
            height: 120vh;
        }
    }

    .contact>main {
        height: 100vh;
        width: 100%;
        border-radius: 0;

        h1 {
            text-align: center;
        }
    }
}


@media screen and (max-width : 700px) {
    .home>main {
        align-items: center;

        h1 {
            font-size: 3rem;
        }
    }

    .home2 {

        height: 120vh;
        flex-direction: column;

        img {
            width: 100%;
        }

        div {
            width: 100%;
        }

    }

    .home3 {
        height: 120vh;
        

        >div {
            text-align: center;
            justify-content: center;
            width: 100%;
            border-radius: 0;

            >p {
                padding: 5rem 2rem ;
                font-size: 0.9rem;
                line-height: 150%;
            }
        }
    }


.home4 {
    align-items: center;

    >div {
        border-radius: 0;
        width: 100%;
        height: unset;
    }

}

.contact > main {
    padding: 1rem;

    h1 {
        margin: 1rem;
    }

    >form{
        margin: 0;
        >div {
            width: 100%;
        }
    }
}

footer{
    height: 50vh;
    grid-template-columns: 1fr;
    padding: 5rem;
    margin-top: 15rem;
    >div{
        align-items: center;
    }
}

}
