@import'color.scss';

nav{
    z-index: 10;
    background-color: $first;
    position: fixed;

    > a{
        color: $dark;
     

        &:hover{
            color: $third;
        }
    }
}